<template>
    <psi-form-layout-section
        title="Ring Central"
        icon="mdi-phone-log-outline"
        btn-label="Save Settings"
        btn-icon="mdi-phone-log-outline"
    >
        <psi-form-section
            :value="data"
            name="update-ring-central"
            label=""
            :fields="fields"
            color="secondary"
            @input="update"
        ></psi-form-section>
    </psi-form-layout-section>
</template>
<script>
export default {
    name: "ring-central",
    components: {},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                {
                    type: "text",
                    name: "username",
                    key: "username",
                    label: "Username",
                    placeholder: "+19997774444",
                    icon: this.$config("icons.name"),
                    hint:
                        "Ring Central user name is your phone number prefix with +1",
                    persistentHint: true,
                    rules: [
                        (v) =>
                            v.length === 12 ||
                            "Ring central username format is invalid",
                        (v) =>
                            v.substring(0, 2) === "+1" ||
                            "Ring central username must start with +1",
                    ],
                    required: true,
                },
                {
                    type: "text",
                    name: "extension",
                    key: "extension",
                    format: "numeric",
                    label: "Extension",
                    icon: this.$config("icons.phone"),
                    required: true,
                },
                {
                    type: "text",
                    name: "password",
                    key: "password",
                    label: "Password",
                    format: "password",
                    icon: this.$config("icons.password"),
                    required: true,
                },
            ],
        };
    },
    computed: {
        data() {
            if (Object.keys(this.user).length > 0) {
                const {
                    ring_central_username: username,
                    ring_central_password: password,
                    ring_central_extension: extension,
                } = this.user;
                return {
                    username,
                    password,
                    extension,
                };
            }
            return {};
        },
    },
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {
        update() {},
    },
};
</script>

<style scoped>
</style>